<template>
    <div>

        <el-button type="primary" class="head-opt" @click="addClickBtn">添加包月项目</el-button>

            <el-table
                :data="tableData"
                style="width: 100%"
                border>

                <el-table-column
                    prop="name"
                    label="名字"
                    width="130">
                </el-table-column>

                <el-table-column
                    prop="realPrice"
                    label="现价"
                    width="130">
                    <template slot-scope="scope">
                        <div>{{ scope.row.realPrice }}分</div>
                    </template>
                </el-table-column>
                
                <el-table-column
                    prop="originalPrice"
                    label="原价"
                    width="130">
                    <template slot-scope="scope">
                        <div>{{ scope.row.originalPrice }}分</div>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="status"
                    label="状态">
                    <template slot-scope="scope">

                        <div v-if="scope.row.status == 1" class="yes">启动</div>
                        <div v-if="scope.row.status == 2" class="no">禁用</div>

                    </template>
                </el-table-column>

                <el-table-column
                    fixed="right"
                    label="操作"
                    width="220">
                    <template slot-scope="scope">

                        <el-button @click.stop="editBtn(scope.row)" type="text" size="small">
                            编辑
                        </el-button>

                    </template>
                </el-table-column>
            
            </el-table>

            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageNumber"
                :page-sizes="[20, 50, 100, 150]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>

            <AddProduct2Pay :visible.sync="AddMoneyPayVisible" @ok="getData" ref="AddMoneyPayRef"></AddProduct2Pay>
    </div>
</template>
  
  
<script>

    import AddProduct2Pay from "./AddProduct2Pay.vue"
    import _ from 'lodash'

  
  export default {
    components: { AddProduct2Pay},
    data() {
      return {
        AddMoneyPayVisible:false,
        tableData:[],
        total:0,
        pageNumber:1,
        pageSize:40,
        queryParams:{},

        
      };
    },
    async mounted() {


        this.getData()


    },
    methods: {
        getData() {
            
            this.$https('queryGoods', {
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                goodsType:2,
                ...this.queryParams
            }).then(res => {

                res.data.records.forEach((val)=>{
                    Object.assign(val, val.goodsVip)
                })
                this.tableData = res.data.records


                this.total = parseInt(res.data.total)


            })
        },
        addClickBtn(){
            this.AddMoneyPayVisible = true
            this.$nextTick(()=>{
                this.$refs.AddMoneyPayRef.start()
            })
        },
        handleSizeChange(size) {
            this.pageSize = size
            this.getData()
        },
        handleCurrentChange(num) {
            this.pageNumber = num
            this.getData()
        },
        editBtn(item){
            
            this.AddMoneyPayVisible = true
            this.$nextTick(()=>{
                item.goodsVip.status = item.status
                item.goodsVip.sort = item.sort

                var goodsItem = _.cloneDeep(item.goodsVip)
                
                this.$refs.AddMoneyPayRef.start(goodsItem, item.id)
            })

        }

    },
  };
</script>
  
<style scoped >
.dialogWrap{
    padding: 20px 0 20px 80px;
    box-sizing: border-box;
    
}
.radioWrap{
    padding: 10px 0;
}
.yes{
    color: rgb(75, 167, 0);
}
.no{
    color: rgb(255, 79, 79);
}
.head-opt{
    margin-bottom: 10px;
}
</style>
  