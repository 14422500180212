<template>
  
    <el-dialog title=""
        :visible.sync="visible"
        width="900px"
        :close-on-click-modal="false"
        :before-close="handleClose"
        class="upload_box">
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules"   label-width="200px">

                <el-form-item label="名字" prop="name">
                    <el-input  v-model="ruleForm.name"  />
                </el-form-item>

                <el-form-item label="原价(分)" prop="originalPrice">
                    <el-input  v-model="ruleForm.originalPrice"  />
                </el-form-item>

                <el-form-item label="现价(分)" prop="realPrice">
                    <el-input  v-model="ruleForm.realPrice"  />
                </el-form-item>

                <el-form-item label="每日GPT3.5次数限制（-1是无限）" prop="dailyGpt3CountLimit">
                    <el-input  v-model="ruleForm.dailyGpt3CountLimit"  />
                    <div class="illustrate"></div>
                </el-form-item>

                <el-form-item label="每日GPT3.5 token限制（-1是无限）" prop="dailyGpt3TokenLimit">
                    <el-input  v-model="ruleForm.dailyGpt3TokenLimit"  />
                    <div class="illustrate"></div>

                </el-form-item>

                <el-form-item label="每日GPT4次数限制（-1是无限）" prop="dailyGpt4CountLimit">
                    <el-input  v-model="ruleForm.dailyGpt4CountLimit"  />
                    <div class="illustrate"></div>

                </el-form-item>

                <el-form-item label="每日GPT4 token限制（-1是无限）" prop="dailyGpt4TokenLimit">
                    <el-input  v-model="ruleForm.dailyGpt4TokenLimit"  />
                    <div class="illustrate"></div>

                </el-form-item>

                <el-form-item label="天数" prop="days">
                    <el-input  v-model="ruleForm.days"  />
                </el-form-item>

                <el-form-item label="GPT3.5上下文条数限制" prop="gpt3ContextLimit">
                    <el-input  v-model="ruleForm.gpt3ContextLimit"  />
                </el-form-item>

                <el-form-item label="GPT4上下文条数限制" prop="gpt4ContextLimit">
                    <el-input  v-model="ruleForm.gpt4ContextLimit"  />
                </el-form-item>

                <el-form-item label="类型" prop="type">
                    <el-select v-model="ruleForm.type" style="width:100%" placeholder="请选择">
                        <el-option label="免费版" :value="1" />
                        <el-option label="周会员" :value="2" />
                        <el-option label="月会员" :value="3" />
                        <el-option label="年会员" :value="4" />
                        <el-option label="日会员" :value="5" />
                        <el-option label="季会员" :value="6" />
                        <el-option label="赠送" :value="7" />
                    </el-select>
                </el-form-item>

                <el-form-item label="是否启用" prop="status">
                    <el-select v-model="ruleForm.status" style="width:100%" placeholder="请选择">
                        <el-option label="启用" :value="1" />
                        <el-option label="禁用" :value="2" />
                    </el-select>
                </el-form-item>
                
                <el-form-item label="排序" prop="sort">
                    <el-input  v-model="ruleForm.sort"  />
                </el-form-item>

            </el-form>
        <!-- 表单结束 -->
        <!--操作按钮-->
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary"  @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
  
  </template>
  
  <script>
  import { isNumber, isNumeric } from '@/utils/utils';

  // 引入组件及方法
  // 引入组件
  export default {
    components: {  },
    // 数据
    data() {
        var nuberCheck = (rule, value, callback)=>{
            if (value === '' || value === null) {
                return callback(new Error('不能为空'));
            }
            console.log("==========", value);
            console.log("==========", value.indexOf);

            if((value+'').indexOf(".") >= 0){
                return callback(new Error('不能有小数点'))
            }
            
            var bool = isNumeric(value)
            if(bool){

                callback()
            }else{
                callback(new Error('只能输入数字'))
            }
        }

      return {
        ruleForm:{
            dailyGpt3CountLimit:0, //每日GPT3.5次数限制，-1表示不限制  0表示不支持
            dailyGpt3TokenLimit:0, // 每日GPT3.5 token限制，-1表示不限制 0表示不支持
            dailyGpt4CountLimit:0, //每日GPT4次数限制，-1表示不限制 0表示不支持
            dailyGpt4TokenLimit:0, //每日GPT4 token限制，-1表示不限制 0表示不支持
            days:0, //天数

            gpt3ContextLimit:0, //GPT3.5上下文条数限制
            gpt4ContextLimit:0, //GPT4上下文条数限制
            name:'', //名字
            originalPrice:0,
            realPrice:0,
            type:'1', //子类型：1 免费版，2 周会员，3 月会员，4 年会员，5 日会员，6 季会员，7 赠送
            status:1, //状态：1 启用，2 禁用
            sort:0


        },
        rules: {
            dailyGpt3CountLimit: [{ validator: nuberCheck, message: '请输入', trigger: 'change' }],
            dailyGpt3TokenLimit: [{ validator: nuberCheck, message: '请输入', trigger: 'change' }],
            dailyGpt4CountLimit: [{ validator: nuberCheck, message: '请输入', trigger: 'change' }],
            dailyGpt4TokenLimit: [{ validator: nuberCheck, message: '请输入', trigger: 'change' }],

            days: [{ required: true, message: '请输入', trigger: 'change' }],
            gpt3ContextLimit: [{ required: true, message: '请输入', trigger: 'change' }],
            gpt4ContextLimit: [{ required: true, message: '请输入', trigger: 'change' }],
            name: [{ required: true, message: '请输入', trigger: 'change' }],
            originalPrice: [{ required: true, message: '请输入', trigger: 'change' }],
            realPrice: [{ required: true, message: '请输入', trigger: 'change' }],
            type: [{ required: true, message: '请输入', trigger: 'change' }],
            status: [{ required: true, message: '请输入', trigger: 'change' }]

        },
        isEdit:false,
        proId:""
      }
    },
    props:{
        visible:Boolean
    },
    watch: {
        visible(){
            if(this.visible){


            }
        }
    },
    //  初始化
    mounted() {
      
    },
  
    methods: {

        start(obj, id){
            if(obj){
                this.ruleForm = obj
                this.isEdit = true
                this.proId = id

            }else{
                this.isEdit = false
                this.ruleForm.dailyGpt3CountLimit = 0
                this.ruleForm.dailyGpt3TokenLimit = 0
                this.ruleForm.dailyGpt4CountLimit = 0
                this.ruleForm.dailyGpt4TokenLimit = 0
                this.ruleForm.days = 0
                this.ruleForm.gpt3ContextLimit = 0
                this.ruleForm.gpt4ContextLimit = 0
                this.ruleForm.name = ''
                this.ruleForm.originalPrice = 0
                this.ruleForm.realPrice = 0
                this.ruleForm.type = 1
                this.ruleForm.status = 1
                this.ruleForm.sort = 0


                

            }
        },
        handleClose(){
            this.$emit('update:visible', false)
        },
        handleSubmit(){

            this.$refs['ruleForm'].validate(async (valid) => {
                if (valid) {
                    

                    var param = {
                        goodsVip:{
                            dailyGpt3CountLimit:this.ruleForm.dailyGpt3CountLimit,
                            dailyGpt3TokenLimit:this.ruleForm.dailyGpt3TokenLimit,
                            dailyGpt4CountLimit:this.ruleForm.dailyGpt4CountLimit,
                            dailyGpt4TokenLimit:this.ruleForm.dailyGpt4TokenLimit,
                            days:this.ruleForm.days,
                            gpt3ContextLimit:this.ruleForm.gpt3ContextLimit,
                            gpt4ContextLimit:this.ruleForm.gpt4ContextLimit,
                            name:this.ruleForm.name,
                            originalPrice:this.ruleForm.originalPrice,
                            realPrice:this.ruleForm.realPrice,
                            type:this.ruleForm.type,
                            status:this.ruleForm.status
                        },
                        goodsType:2,
                        status:this.ruleForm.status,
                        sort:this.ruleForm.sort
                    }

                    if(this.isEdit){
                        param.id = this.proId

                        this.$https('updateGoods', param).then(res => {
                            this.handleClose()
                            this.$emit('ok')
                        })
                    }else{

                        this.$https('addGoods', param).then(res => {
                            this.handleClose()
                            this.$emit('ok')
                        })
                    }
                    


                    
                } else {
                    
                }
            });
            


        }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  
.illustrate{
    color: #f55;
    position: relative;
    left: -155px;
    top: -20px;
    font-size: 12px;
    width: 150px;
}
  
  </style>
  